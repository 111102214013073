import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from 'styled-components'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faShoppingCart, faChevronRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import loadable from '@loadable/component'

import Container from "./Container"
import SideMenuButton from "./SideMenuButton"
import HoverButton from "../buttons/HoverButton"
import Button from "../buttons/Button"

import { UserContext} from "../../state/UserContext"
import { colors, devices, storage } from "../../settings"
import { CartContext } from '../../state/CartContext';
import { properCaseProductName } from "../../utilities/utils"

import HeadsetIcon from "../icons/HeadsetIcon"

const ProductsPanel = loadable(() => import('../panels/ProductsPanel'));
const TemplatesPanel = loadable(() => import('../panels/TemplatesPanel'));
const WhyPanel = loadable(() => import('../panels/WhyPanel'));

const HeaderBar = styled.header`
`
const AnnouncementBar = styled.div`
  padding: 5px;
  text-align: center;
  background-color: #666;
  color: white;
  position: relative;
  top: -5px;
`
const UserBar = styled.div`
  background-color: #9c86ff;
  color: white;
  padding: 0px;
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0px;
  font-size: 15px;

  @media ${devices.mobile} {
    padding: 1px;
    position: absolute;
    top: 62px;  
    z-index: 998;  
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      color: white;
      margin: 3px;
      font-weight: 400;
      font-size: 15px;
      text-align: left;

      @media ${devices.mobile} {
        display: none;
      }
    }
    header {
      display: none;
      color: white;
      margin: 5px;
      font-weight: 400;
      font-size: 15px;
      text-align: left;

      @media ${devices.mobile} {
        display: block;
      }
    }

    > div {
      display: flex;
      align-items: center;

      @media ${devices.mobile} {
        display: none;   
      }

      svg {
        margin-right: 6px;

        @media ${devices.mobile} {
          margin-left: 6px;
        }
      }
    }

    a {
      color: white;
      padding: 0px 10px;
    }
    a:nth-child(1) {
      border-right: 1px solid white;
    }
    a:nth-child(2) {
      border-left: 1px solid white;
    }

    button {
      background-color: white;
      padding: 2px 7px;
    }
  }
`
const MainBar = styled.div`
  position: fixed;
  top: 27px;
  left: 0px;
  right: 0px;
  z-index: 998;  
  background-color: white;

  color: ${colors.headerText};
  padding: 0px;
  font-size: 20px;
  border-bottom: 1px solid ${colors.border};

  @media ${devices.laptop} {
    padding: 0px 10px;
  }
  @media ${devices.tablet} {
    padding: 0px;
  }
  @media ${devices.mobile} {
    position: absolute;
    top: 0px;
  }  
`
const Items = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;

  button.menu {
    display: none;

    @media ${devices.tablet} {
      display: block;
      margin-right: 10px;
      padding: 10px;
    }
    @media ${devices.mobile} {
      display: block;
    }
  }

  > a {
    color: black;
    flex: 1;
    padding: 0px 15px;
    text-align: center;

    @media ${devices.mobile} {
      flex: 0;
      padding: 0px;
      text-align: left;
    }

    &:hover {
      font-weight: 500;
    }
    &.active {
      > span {
        border-bottom: 3px solid ${colors.red};
        font-weight: 500;
      }
    }
    &:nth-child(1) {
      padding-left: 0px;
    }
    &:last-child {
      padding-right: 0px;
    }
    &.logo {
      flex: 1;
      margin-left: 0px;
      margin-right: 0px;
      width: 200px;
      height: 50px;
      background-image: url(${storage.images}logo.png?v2);
      background-repeat: no-repeat;
      background-size: contain;

      @media ${devices.tablet} {
        height: 36px;
      }
      @media ${devices.laptop} {
        height: 36px;
      }
    }
    &.panel {
      @media ${devices.tablet} {
        display: none;
      }
      @media ${devices.mobile} {
        display: none;
      }
    }
    &.cart, &.help {
      button {
        background-color: white;
        border: 0px; 
        
        &:hover {
          transform: none;
        }
      }
    }
    &.cart {
      display: none;
      text-align: center;

      @media ${devices.mobile} {
        display: block;

        &.empty {
          display: none;
        }
      }

      button {
        @media ${devices.mobile} {
          display: block;
        }          
      }

      svg {
        color: ${colors.green};
        width: 26px;
        height: 26px;
      }
    }
    &.help {
      text-align: right;
      font-size: 13px;

      svg {
        margin-right: 6px;
        margin-top: 5px;
        height: 26px;
        width: 26px;
        color: #999;

        @media ${devices.mobile} {
          margin-top: 0px;
          margin-right: 0px;
          margin-bottom: 6px;
          height: 22px;
          width: 22px;
        }
      }
    
      span {
        display: block;
      }    

      button {
        display: flex;

        @media ${devices.mobile} {
          display: block;
        }
      }
      button > div {
        display: block;

        @media ${devices.mobile} {
          display: none;
        }
      }
      button > small {
        display: none;

        @media ${devices.mobile} {
          display: block;
        }
      }
      button svg path {
        @media ${devices.mobile} {
          fill: black !important;
        }
      }
    }
  }

  button {
    padding: 15px;
    margin: 0px;
    font-size: 16px;
    white-space: nowrap;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;

    @media ${devices.mobile} {
      display: none;
      padding: 10px;
      margin: 0px 7px 0px 0px;
      font-size: inherit;
    }
  }
`
const StickyBar = styled.div`
  background-color: white;
  position: fixed;
  top: -75px;
  left: 0px;
  right: 0px;
  z-index: 999;  
  padding: 10px;
  box-shadow: 0px 5px 5px rgba(0,0,0,.35);
  transition: top .4s cubic-bezier(.3, .73, .3, .74);

  @media ${devices.mobile} {
    &.stuck {
      top: 0px;
    }
  }

  button {
    display: block !important;
    margin-right: 0px;
  }
`

const Panels = styled.div`
  position: fixed;
  top: 90px;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  display: ${props => props.show ? "block":"none"};
  overflow: hidden;
  min-height: 290px;
  border: 1px solid #ccc;
  border-top: 0px;
  box-shadow: 10px 10px 15px rgba(0,0,0,.5);  
`
const Panel = styled.div`
  background-color: white;
  box-sizing: border-box;
  margin: 0px auto;
  padding: 0px;
  width: 900px;
  min-height: 290px;
  overflow: hidden;
  display: ${props => props.show ? "block":"none"};

  @media ${devices.mobile} {
    width: 100%;
  }   
`

const Header = (props) => {
  const announcement = null;//"We will be closed until January 2nd to enjoy the holiday with our families.  We will continue work on projects and shipping signs when we return.";

  const userContext = useContext(UserContext);
  const cartContext = useContext(CartContext);

  const [scrollPos, setScrollPos] = useState(0);
  const [isSticky, setIsSticky] = useState(false)

  let timeoutId = null;

  const [panel, setPanel] = useState(null);
  const [section, setSection] = useState("");
  const [builderParams, setBuilderParams] = useState("from=Header");
  const [tempClose, setTempClose] = useState(false);

  function listenToScroll() {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    //const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    //const scrolled = winScroll / height;
    
    setScrollPos(winScroll);
  }

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)

    return function cleanup() {
      window.removeEventListener('scroll', listenToScroll)
    };
  }, []);

  useEffect(() => {
      setIsSticky(scrollPos > 150);
  }, [scrollPos]);

  useEffect(
    () => {
      let path = props.location && props.location.pathname ? props.location.pathname : "";
      let pathSection = "";
      let from="Header";

      if (path) {
        const pathParts = path.split("/");

        if (pathParts.length > 1) {
          pathSection = pathParts[1]

          if (pathSection == "products" && pathParts.length > 2) {
            from = "Header" + properCaseProductName(pathParts[2]);
          }
        }
      }

      setBuilderParams("from=" + from);
      setSection(pathSection)
    }, 
    [props.location]
  ); 

  function onMouseOver(menu, event) {
    if (timeoutId) clearTimeout(timeoutId);

    timeoutId = setTimeout( () => {
        setPanel(menu);
    }, 300);
  }
  function onMouseLeave(menu, event) {
    if (timeoutId) clearTimeout(timeoutId);

    timeoutId = setTimeout( () => {
        setPanel(null);
    }, 400);
  }
  function onClick() {
    if (timeoutId) clearTimeout(timeoutId);
    setPanel(null);
  }
  function onPanelLink() {
    setTempClose(true);
    setPanel(null)

    setTimeout(() => {
      setTempClose(false);
    }, 250)
  }

  return (
    <>
      {announcement && 
        <AnnouncementBar>
          {announcement}
        </AnnouncementBar>
      }
      <UserBar>
        <Container>
          <h1>Store Front Channel Letters &amp; Lit Shape Signs, Built Fast &amp; Free Shipping</h1>
          <header>Design Custom Lit Signs &amp; Channel Letters</header>

          <div>
            <Link to="/support/">Support</Link>
            <Link to="/account/">Your Account</Link>

            { (cartContext.haveCart() && !props.checkout) && 
                <Link to="/checkout/">
                  <HoverButton
                    icon={faShoppingCart}
                    color="green"
                    size="small"
                    label="Cart"
                    badge={cartContext.cart.items.length}
                  />
                </Link>
              }
          </div>
        </Container>
      </UserBar>

      <HeaderBar>
        <MainBar mode={props.mode || "site"}>
          <Container mode={props.mode || "site"}>
          <Items>
            <SideMenuButton /> 
            <Link to="/" className="logo">
            </Link>
            
            <Link 
              to="/products/"
              className={"panel " + (section === "products" ? "active":"")}
              onMouseEnter={(e) => onMouseOver("products", e)}
              onMouseLeave={(e) => onMouseLeave("products", e)} 
              onClick={onClick}
            >
              <span>PRODUCTS</span>
            </Link>
            <Link 
              to="/templates/"
              className={"panel " + (section === "templates" ? "active":"")}
              onMouseEnter={(e) => onMouseOver("templates", e)}
              onMouseLeave={(e) => onMouseLeave("templates", e)} 
              onClick={onClick}
            >
              <span>SIGN TEMPLATES</span>
            </Link>
            <Link 
              to="/why/"
              className={"panel " + (section === "why" ? "active":"")}
              onMouseEnter={(e) => onMouseOver("why", e)}
              onMouseLeave={(e) => onMouseLeave("why", e)} 
              onClick={onClick}
            >
              <span>WHY SIGNMONKEY</span>
            </Link>

            <a href="tel:8008630107" className="help" onClick={props.onHelp}>
              <button style={{}}>
                <FontAwesomeSvgIcon icon={faPhone} />
                <div>
                  <div>Talk to an Expert</div>
                  <span>(800) 863-0107</span>
                </div>
                <small>Help</small>
              </button>
            </a>

            <Link to="/checkout/" className={"cart " + (cartContext.haveCart() && !props.checkout ? "":"empty")}>
              <button>
                <FontAwesomeSvgIcon icon={faShoppingCart} />
                <div><small>Cart</small></div>
              </button>
            </Link>

            <Link to={"/builder/?" + builderParams}>
              <Button 
                label="Design & Price Sign"
                labelMobile="Design & Price"
                color={props.type == "builder" ? "lightgrey":"red"}
                icon={faChevronRight}
              />
            </Link>
          </Items>
        </Container>
        </MainBar>
        <Panels show={panel !== null && !tempClose} isLoggedIn={userContext.isAuthenticated()}>
            {panel==="products" && 
              <Panel 
                  show={true}
                  onMouseOver={(e) => onMouseOver("products", e)}
                  onMouseLeave={(e) => onMouseLeave("products", e)} 
              >
                  <ProductsPanel linkClicked={onPanelLink} />
              </Panel>
            }
            {panel==="templates" && 
              <Panel 
                  show={true}
                  onMouseOver={(e) => onMouseOver("templates", e)}
                  onMouseLeave={(e) => onMouseLeave("templates", e)} 
              >
                  <TemplatesPanel linkClicked={onPanelLink} />
              </Panel>
            }
            {panel==="why" && 
              <Panel 
                  show={true}
                  onMouseOver={(e) => onMouseOver("why", e)}
                  onMouseLeave={(e) => onMouseLeave("why", e)} 
              >
                  <WhyPanel linkClicked={onPanelLink} />
              </Panel>
            }
        </Panels>
      </HeaderBar> 

      <StickyBar className={isSticky ? "stuck":""}>
        <Items>
          <Link to="/" className="logo">
          </Link>
          
          <Link to="/builder/?from=HeaderSticky">
            <Button 
              label="Design & Price Sign"
              labelMobile="Design & Price"
              color="red"
              icon={faChevronRight}
            />
          </Link>
        </Items>
      </StickyBar>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
